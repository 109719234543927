<template>
  <div>
    <b-modal
      id="modal-create-indication"
      modal-class="modal-import"
      :title="isEdit ? 'Editar Indicação' : 'Cadastrar Indicação'"
      centered
      hide-footer
      hide-header-close
      no-close-on-backdrop
    >
      <b-form ref="formCreateUser" @submit.prevent="submit">
        <div class="form-row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Indicado Por
              </label>
              <input
                type="number"
                id="indicated_by"
                v-model="item.indicated_by"
                minlength="5"
                name="indicated_by"
                class="form-control"
                :class="{ 'is-invalid': $v.item.indicated_by.$error }"
              />
            </div>
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Indicado
              </label>
              <input
                type="number"
                id="indicated"
                v-model="item.indicated"
                name="indicated"
                class="form-control"
                :class="{ 'is-invalid': $v.item.indicated.$error }"
              />
            </div>
          </div>
        </div>

        <div class="form-row justify-content-end mt-2">
          <div class="col-2">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              block
              @click="$bvModal.hide('modal-create-indication')"
            >
              Fechar
            </b-button>
          </div>
          <div class="col-3">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-2"
              type="submit"
              block
              :disabled="submitted"
            >
              <div v-if="submitted">
                <b-spinner small variant="light" />
              </div>
              <div v-else>{{ isEdit ? "Editar" : "Cadastrar" }}</div>
            </b-button>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BForm, BButton, BSpinner } from "bootstrap-vue";
import { required, minLength, email } from "vuelidate/lib/validators";
import axios from "axios";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BModal,
    BForm,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    item: {
      uuid: "",
      indicated_by: "",
      indicated: "",
    },
    submitted: false,
    isEdit: false,
  }),
  validations: {
    item: {
      indicated_by: {
        required,
      },
      indicated: {
        required,
      },
    },
  },
  methods: {
    clearForm() {
      this.item = {
        uuid: "",
        indicated_by: "",
        indicated: "",
      };
    },
    submit() {
      this.$v.item.$touch();
      this.submitted = true;

      if (!this.$v.item.$error) {
        if (this.isEdit) {
          this.$store
            .dispatch("Indications/update", this.item)
            .then(() => {
              this.clearForm();
              this.$v.item.$reset();
              this.notifyDefault("success");
              this.$bvModal.hide("modal-create-indication");
              this.$emit("getData");
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          this.$store
            .dispatch("Indications/create", this.item)
            .then(() => {
              this.clearForm();
              this.$v.item.$reset();
              this.notifyDefault("success");
              this.$bvModal.hide("modal-create-indication");
              this.$emit("getData");
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      } else {
        this.submitted = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
  },
  mounted() {
    this.$root.$on("open:modal-create-indication", (data) => {
      if (data.item && data.isEdit) {
        this.isEdit = data.isEdit;
        this.item = {
          uuid: data.item.uuid,
          indicated_by: data.item.indicated_by,
          indicated: data.item.indicated,
        };
      } else {
        this.isEdit = false;
        this.clearForm();
      }
      this.$bvModal.show("modal-create-indication");
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-indication");
  },
};
</script>
