var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-1" },
        [
          _c(
            "b-form",
            {
              ref: "formFilter",
              staticClass: "m-0",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.fetchIndications()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group mb-md-0" },
                      [
                        _c(
                          "v-select",
                          {
                            attrs: {
                              options: _vm.filterOptions,
                              placeholder: "Selecione o filtro de busca...",
                              clearable: false,
                            },
                            model: {
                              value: _vm.filters.searchField,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "searchField", $$v)
                              },
                              expression: "filters.searchField",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v("Nenhum critério encontrado")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group mb-md-0" },
                      [
                        _c("b-form-input", {
                          attrs: {
                            disabled: !_vm.filters.searchField,
                            placeholder: "Buscar...",
                          },
                          on: { input: _vm.fetchIndications },
                          model: {
                            value: _vm.filters.searchValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "searchValue", $$v)
                            },
                            expression: "filters.searchValue",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group mb-md-0" },
                      [
                        _c("flat-pickr", {
                          staticClass: "form-control",
                          attrs: {
                            placeholder: "Data de criação",
                            config: {
                              mode: "range",
                              altInput: true,
                              dateFormat: "Y-m-d",
                              altFormat: "d/m/Y",
                              locale: "pt",
                            },
                          },
                          on: { input: _vm.fetchIndications },
                          model: {
                            value: _vm.filters.rangeDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "rangeDate", $$v)
                            },
                            expression: "filters.rangeDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c("div", { staticClass: "m-2" }, [
            _c(
              "div",
              { staticClass: "row justify-content-end align-items-center" },
              [
                _c(
                  "div",
                  { staticClass: "col-auto d-flex align-items-center" },
                  [
                    _c("span", { staticClass: "mr-2 font-weight-bold" }, [
                      _vm._v("Itens por página"),
                    ]),
                    _c("v-select", {
                      staticStyle: { width: "100px" },
                      attrs: {
                        label: "title",
                        required: "",
                        options: _vm.perPageOptions,
                        clearable: false,
                      },
                      on: { input: _vm.setPerPageSelected },
                      model: {
                        value: _vm.filters.perPage,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "perPage", $$v)
                        },
                        expression: "filters.perPage",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.fields,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(action)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex-between" },
                      [
                        _vm.$can("Indicação - Excluir", "Comercial")
                          ? _c("feather-icon", {
                              staticClass: "cursor-pointer cursor text-danger",
                              attrs: { icon: "Trash2Icon", size: "16" },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmDelete(item.uuid)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.$can("Indicação - Editar", "Comercial")
                          ? _c("feather-icon", {
                              staticClass: "cursor-pointer cursor",
                              attrs: { icon: "EditIcon", size: "16" },
                              on: {
                                click: function ($event) {
                                  return _vm.editItem(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(total)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("toCurrency")(item.total)) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(order_id)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm.$can("Pedido - Visualizar", "Comercial")
                      ? _c(
                          "b-link",
                          {
                            attrs: {
                              to: {
                                name: "transactions-orders-preview",
                                params: { uuid: item.order_uuid },
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.order_id) + " ")]
                        )
                      : _c("span", [_vm._v(" " + _vm._s(item.order_id) + " ")]),
                  ]
                },
              },
              {
                key: "cell(created_at)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(_vm.formatDate(item.created_at)) + " "),
                  ]
                },
              },
              {
                key: "cell(updated_at)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(_vm.formatDate(item.updated_at)) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              "rows-table": _vm.rowsTable,
              "current-page": _vm.filters.currentPage,
              "per-page": _vm.filters.perPage,
              "total-rows": _vm.totalRows,
            },
          }),
        ],
        1
      ),
      _c("IndicationModalCreate", { on: { getData: _vm.getData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }