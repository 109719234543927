<template>
  <div>
    <b-card class="mb-1">
      <b-form class="m-0" ref="formFilter" @submit.prevent="fetchIndications()">
        <div class="form-row">
          <b-col cols="12" md="3">
            <div class="form-group mb-md-0">
              <v-select
                :options="filterOptions"
                v-model="filters.searchField"
                placeholder="Selecione o filtro de busca..."
                :clearable="false"
              >
                <span slot="no-options">Nenhum critério encontrado</span>
              </v-select>
            </div>
          </b-col>

          <b-col cols="12" md="3">
            <div class="form-group mb-md-0">
              <b-form-input
                v-model="filters.searchValue"
                :disabled="!filters.searchField"
                placeholder="Buscar..."
                @input="fetchIndications"
              />
            </div>
          </b-col>

          <b-col cols="12" md="3">
            <div class="form-group mb-md-0">
              <flat-pickr
                placeholder="Data de criação"
                v-model="filters.rangeDate"
                class="form-control"
                @input="fetchIndications"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </b-col>
        </div>
      </b-form>
    </b-card>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <div class="row justify-content-end align-items-center">
          <div class="col-auto d-flex align-items-center">
            <span class="mr-2 font-weight-bold">Itens por página</span>
            <v-select
              style="width: 100px"
              label="title"
              required
              v-model="filters.perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </div>
        </div>
      </div>
      <b-table
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
      >
        <template #cell(action)="{ item }">
          <div class="d-flex-between">
            <feather-icon
              icon="Trash2Icon"
              size="16"
              @click="confirmDelete(item.uuid)"
              v-if="$can('Indicação - Excluir', 'Comercial')"
              class="cursor-pointer cursor text-danger"
            />
            <feather-icon
              icon="EditIcon"
              size="16"
              @click="editItem(item)"
              v-if="$can('Indicação - Editar', 'Comercial')"
              class="cursor-pointer cursor"
            />
          </div>
        </template>
        <template #cell(total)="{ item }">
          {{ item.total | toCurrency }}
        </template>
        <template #cell(order_id)="{ item }">
          <b-link
            :to="{
              name: 'transactions-orders-preview',
              params: { uuid: item.order_uuid },
            }"
            v-if="$can('Pedido - Visualizar', 'Comercial')"
          >
            {{ item.order_id }}
          </b-link>
          <span v-else>
            {{ item.order_id }}
          </span>
        </template>
        <template #cell(created_at)="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
        <template #cell(updated_at)="{ item }">
          {{ formatDate(item.updated_at) }}
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="totalRows"
      />
    </b-card>
    <IndicationModalCreate @getData="getData" />
  </div>
</template>

<script>
import {
  BCard,
  BIcon,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BTable,
  BButton,
  BPagination,
  BMedia,
  BTooltip,
  BBadge,
  BFormRow,
  BSpinner,
  BLink,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import moment from "moment";

import LabelCustom from "@/components/form/LabelCustom.vue";

import IndicationModalCreate from "./Modal/Create.vue";

export default {
  components: {
    flatPickr,
    CustomPaginateTable,
    BCard,
    BFormRow,
    BForm,
    BIcon,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BTable,
    BButton,
    BPagination,
    BMedia,
    BTooltip,
    BBadge,
    vSelect,
    LabelCustom,
    BLink,
    BListGroup,
    BListGroupItem,
    IndicationModalCreate,
  },
  data() {
    return {
      filters: {
        indicated_by: "",
        indicated: "",
        currentPage: 1,
        perPage: 10,
        rangeDate: null,
        searchValue: "",
        searchField: "",
      },
      sortBy: "created_at",
      sortDesc: false,
      btnSearch: false,
      optionsIndications: [],
      submited: false,
      totalRows: 0,
      rowsTable: 0,
      dataItems: [],
      perPageOptions: [10, 25, 50, 100],
      filterOptions: [
        { label: "Indicado Por", value: "indicated_by" },
        { label: "Indicado", value: "indicated" },
        { label: "Número da Sorte", value: "lucky_number" },
      ],
      fields: [
        {
          key: "id",
          label: "ID",
          class: "text-center",
          sortable: true,
        },
        {
          key: "indicated_by",
          label: "Indicado por",
          class: "text-center",
          sortable: true,
        },
        {
          key: "indicated",
          label: "Indicado",
          class: "text-center",
          sortable: true,
        },
        {
          key: "lucky_number",
          label: "Numero da Sorte",
          class: "text-center",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Data de Criação",
          class: "text-center",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Última Atualização",
          class: "text-center",
          sortable: true,
        },
        {
          key: "action",
          label: "",
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
    };
  },
  computed: {
    // Retorna o label do campo de busca com base no critério selecionado
    getFieldLabel() {
      if (this.filters.searchField.value === "indicated_by") {
        return "Indicado Por";
      } else if (this.filters.searchField.value === "indicated") {
        return "Indicado";
      } else if (this.filters.searchField.value === "lucky_number") {
        return "Número da Sorte";
      }
      return "";
    },
  },
  methods: {
    async downloadCSV() {
      try {
        // let filtersSearch = {
        //   currentPage: 1,
        //   perPage: 1000,
        // };
        // this.$store
        //   .dispatch("Indications/all", filtersSearch)
        //   .then((res) => {
        //     if (res) {
        //       this.dataItems = res.data;
        //       const toCSV = this.dataItems.map((item) => {
        //         return {
        //           ID: item.id,
        //           "Indicado Por": item.indicated_by,
        //           Indicado: item.indicated,
        //           "Numero da Sorte": item.lucky_number,
        //           "Data de Criação": moment(item.created_at).format(
        //             "DD/MM/YYYY HH:mm:ss"
        //           ),
        //           "Última Atualização": moment(item.updated_at).format(
        //             "DD/MM/YYYY HH:mm:ss"
        //           ),
        //         };
        //       });
        //       const wb = XLSX.utils.book_new();
        //       const ws = XLSX.utils.json_to_sheet(toCSV);
        //       XLSX.utils.book_append_sheet(wb, ws, "Tabela de Indicações");
        //       XLSX.writeFile(wb, "indicacoes.xlsx");
        //     }
        //   })
        //   .finally(() => {
        //     this.btnSearch = false;
        //   });
      } catch (error) {
        // console.error("Erro ao exportar CSV:", error);
      }
    },
    async fetchIndications(searchedValue) {
      const searchField = this.filters.searchField.value;
      const searchValue = searchedValue;
      const rangeDate = this.filters.rangeDate;

      if (searchedValue?.length > 2 || rangeDate) {
        const query = {
          [searchField]: searchValue,
          currentPage: 1,
          perPage: this.filters.perPage,
        };

        if (rangeDate && rangeDate.split(" até ").length > 1) {
          const splitedRangeDate = rangeDate.split(" até ");
          query.created_at_start = splitedRangeDate[0];
          query.created_at_end = splitedRangeDate[1];
        }

        this.$store
          .dispatch("Indications/all", query)
          .then((res) => {
            if (res) {
              this.dataItems = res.data;
              this.rowsTable = res.data.length;
              this.totalRows = res.meta.total;
              this.filters.currentPage = res.meta.current_page;
            }
          })
          .finally(() => {});
        this.optionsIndications = this.dataItems;
      } else if (searchedValue?.length === 0) {
        this.getData();
      }
    },
    editItem(item) {
      this.$root.$emit(`open:modal-create-indication`, {
        status: true,
        elementName: "modal-create-indication",
        item,
        isEdit: true,
      });
    },
    setPerPageSelected(value) {
      this.filters.perPage = value;
      this.getData();
    },
    formatDate(dateString) {
      return moment(dateString).format("DD/MM/YYYY HH:mm:ss");
    },
    async getData() {
      this.btnSearch = true;
      let filtersSearch = {
        currentPage: this.filters.currentPage,
        perPage: this.filters.perPage,
      };

      this.$store
        .dispatch("Indications/all", filtersSearch)
        .then((res) => {
          if (res) {
            this.dataItems = res.data;
            this.rowsTable = res.data.length;
            this.totalRows = res.meta.total;
            this.filters.currentPage = res.meta.current_page;
          }
        })
        .finally(() => {
          this.btnSearch = false;
        });
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Tem certeza que deseja deletar esse registro? Esta ação não pode ser desfeita.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Indications/delete", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
  directives: {
    Ripple,
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
    this.$root.$off("open:modal-create-indication");
  },
  async mounted() {
    this.getData();

    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.perPage = res._perPage;
      this.getData();
    });

    this.$root.$on("open:export-table", () => {
      this.$swal({
        title: "Exportar Indicações",
        text: "Enviaremos para o seu e-mail e WhatsApp, o link para você baixar todas as indicações.",
        iconHtml: '<i class="bi bi-download"></i>',
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Gerar relatório",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.downloadCSV();
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.swal2-icon-content {
  i {
    font-size: 2rem;
    color: #28c76f;
  }
}

.swal2-icon-show {
  border-color: #28c76f;
}
</style>
